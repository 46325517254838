import Layout from "@/layout";
import { staticPage } from "@/lib";
import { breadcrumbSingle } from "@jog/react-components";
import { prismicClient } from "@jog/shared";
import { asText } from "@prismicio/helpers";
import { Breadcrumbs, Head } from "components";
import { LoginContent } from "containers";
export async function getStaticProps(context) {
    return staticPage(context, async ({ previewData }) => prismicClient({ previewData })
        .getSingle("login")
        .then((res) => res.data));
}
export default function LoginPage({ data }) {
    return (<Layout>
            <Breadcrumbs list={breadcrumbSingle("login")}/>
            <Head title={asText(data.meta_tag_title)} description={asText(data.meta_tag_description)} robots={asText(data.meta_tag_robots)}/>
            <LoginContent />
        </Layout>);
}
